import styled, { css } from 'styled-components';

const getBackgroundImageUrl = ({
  isGalleryHeader,
  isCurrentItemPage,
  isWebView
}) => {
  switch (true) {
    case isGalleryHeader:
      return '/static/images/item/buttons-favorite-bordered.svg';
    case isCurrentItemPage:
      return '/static/images/item/buttons-favorite.svg';
    case isWebView:
      return '/static/images/webview/icon-favorite.svg';
    default:
      return '/static/images/icons-favorite.svg';
  }
};

export const BookmarkContainer = styled.div`
  background-position: 0 0;
  display: inline-block;
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 4;

  ${({ isGalleryHeader, isCurrentItemPage }) =>
    isGalleryHeader || isCurrentItemPage
      ? { top: '22px', right: '15px' }
      : { top: '10px', right: '10px' }};

  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateZ(0);
  background-image: url(${props => getBackgroundImageUrl(props)});

  ${({ isActive }) =>
    isActive &&
    css`
      background-position: 0 -20px;
    `}
`;

export const BookmarkLink = styled.a`
  float: none;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`;
