import styled, { css } from 'styled-components';
import { Typography } from '../../ui/Typography';
import { FlexCenterAligned, FlexibleDiv } from '../../../styled/common';

export const CategoriesContainer = styled(FlexibleDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  width: 100%;
  padding: 0 15px;
  margin: 20px 0;
  gap: 10px;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const transitionStyles = (transition: string) => css`
  transition: ${transition} 0.3s ease;
`;

const containerStateStyles = (color: string) => css`
  span {
    ${transitionStyles('color')}
    color: ${color};
  }

  svg path {
    ${transitionStyles('fill')}
    fill: ${color};
  }

  &:nth-of-type(5) {
    svg rect,
    svg path {
      ${transitionStyles('stroke')}
      stroke: ${color};
    }
  }
`;

export const CategoryContainer = styled(FlexCenterAligned)`
  height: 48px;
  padding: 4px 15px 4px 5px;
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  border-radius: 100px;
  cursor: pointer;
  ${transitionStyles('background-color')};
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${({ theme }) => containerStateStyles(theme.colors.systemColors.blue)}
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.systemColors.blue};
    ${({ theme }) => containerStateStyles(theme.colors.text.white)}
  }
`;

export const CategoryIcon = styled.div`
  padding: 9px;
`;

export const CategoryText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.primary};
`;
