import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import {
  fillCategoriesWithData,
  getCategoriesData
} from '../../../utils/helpers/search';
import { categoriesQuery } from '../../../queries';
import useBugsnagNotifier from '../../../hooks/useBugsnagNotifier';
import {
  CategoriesContainer,
  CategoryContainer,
  CategoryIcon,
  CategoryText
} from './styled';
import { NewSearchCategoriesProps, NewSearchCategoryProps } from './types';
import useScreenSize from '../../../hooks/useScreenSize';

const NewSearchCategory = ({
  icon,
  text,
  onClick,
  stat
}: NewSearchCategoryProps) => {
  const { isSmallDesktop } = useScreenSize();
  return (
    <CategoryContainer
      tabIndex={0}
      role="button"
      data-cy={stat}
      data-stat={stat}
      onClick={onClick}
      onKeyPress={onClick}
      $isDesktop={isSmallDesktop}
    >
      <CategoryIcon>
        <Icon
          width={null}
          height={null}
          color="#fff"
          indent={false}
          IconComponent={icon}
        />
      </CategoryIcon>
      <CategoryText tag="body_1">{text}</CategoryText>
    </CategoryContainer>
  );
};

export const NewSearchCategories = ({
  change,
  submit
}: NewSearchCategoriesProps) => {
  const { data, error } = useQuery(categoriesQuery);
  const {
    i18n: { language }
  } = useTranslation();
  const categoriesData = getCategoriesData({ data, language });
  const filledCategories = fillCategoriesWithData(categoriesData);

  useBugsnagNotifier(error);

  return (
    <CategoriesContainer data-cy="categories-container">
      {filledCategories.map(category => (
        <NewSearchCategory
          key={category.id}
          stat={category.stat}
          icon={category.icon}
          text={category.name}
          onClick={() => {
            change('category_id', category.id);
            change('category_touched', true);
            submit();
          }}
        />
      ))}
    </CategoriesContainer>
  );
};
